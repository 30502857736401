
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.ticket-field {
  &__label {
    font-weight: bold;
    font-size: 14px;
    @include for-size(phone-portrait-down) {
      font-size: 12px;
    }
  }
  &__value {
    margin: 4px 0 0 0;
    font-size: 14px;
    @include for-size(phone-portrait-down) {
      font-size: 12px;
    }
  }
  &_group {
    padding: 24px;
    border-radius: 12px;
    border: 1px rgba(232, 232, 238, 1) solid;
    background: #fff;
    &-gray {
      background: #F4F4F5;
    }
    @include for-size(phone-portrait-down) {
      padding: 8px;
      margin-top: 12px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 20px;
    @include for-size(phone-portrait-down) {
      margin-bottom: 8px;
    }
  }
}
