
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

table {
  width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #ddd;
  th, td {
    padding: 5px;
    border: 1px solid #ddd;
  }
}
